<template>

  <a-layout id="components-layout-demo-side" style="min-height: 100vh">

    <a-layout-sider v-model="collapsed" collapsible :theme="theme" :trigger="null">
      <div style="max-width:200px;text-align: center;padding:20px 0;cursor: pointer;" @click="toHome">
        <span style="font-size: 18px;text-align: center;color: #f8a326;">后台管理系统</span>
      </div>
      <Menu v-show="isHidden" :menus="menus"></Menu>
    </a-layout-sider>
    <a-layout>
      <!--      id="build"的作用是当页面跳转时scrollTo该位置-->
      <a-layout-header class="ant-pro-global-header" style="background: #fff; padding: 0;" id="build">

        <div style="display:flex;justify-content:space-between;align-items: center; padding: 0  24px;">

          <div style="display:flex;justify-content: flex-start;align-items: center;">
            <a-icon style="font-size: 18px;color: #f8a326;padding:0 8px;"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="() => (collapsed = !collapsed)" />
            <a-icon style="font-size: 18px;color: #f8a326;padding:0 8px;" class="icon_hold" @click="fresh"
              type="sync" />

            <div style="display:flex;margin-left:10px;">
              <a-breadcrumb separator=">">
                <a-breadcrumb-item v-for="(breadcrumb, index) in Breadcrumbs" :key="index">
                  <span style="font-size: 1.1rem;">{{ breadcrumb.title }}</span>
                  
                </a-breadcrumb-item>
                <div style="display:inline" v-if="Breadcrumbs[Breadcrumbs.length - 1]">
                <span>
                  描述：{{ Breadcrumbs[Breadcrumbs.length - 1]['description'] }}
                </span>
              </div>
              </a-breadcrumb>
            </div>
          </div>
          <div>
            <section @click="isScreenFull"
              style="display:inline;cursor: pointer;font-size:1.2rem;line-height:1.2rem;color: #f8a326;">
              <a-icon type="fullscreen" v-if="isFullscreenMark" id="screenfull" />
              <a-icon type="fullscreen-exit" v-else id="screenfull" />
            </section>
            <!--            <span class="role_holder">角色：{{role}}{{ role ? role : '暂无' }}</span>-->
            <span class="role_holder">角色：{{ role }}</span>
            <a-dropdown :trigger="['click']">
              <Avatar shape="square" size="large" style="cursor: pointer" @click="e => e.preventDefault()"
                :style="{ backgroundColor: 'red', verticalAlign: 'middle' }">
                {{ user.name }}
              </Avatar>

              <a-menu slot="overlay">
                <a-menu-item>
                  <a href="javascript:;" @click="logout">退出登录</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <Icon type="caret-down" />
          </div>
        </div>
      </a-layout-header>

      <a-layout-content style="margin: 10px 10px">

        <div style="padding: 10px; background: #fff;minHeight: calc(100vh - 270px)">
          <a-spin :spinning="spinning">
            <router-view></router-view>
          </a-spin>
        </div>
      </a-layout-content>
      <a-layout-footer style="text-align: center">
        后台管理系统 ©2022
      </a-layout-footer>
      <a-back-top />
    </a-layout>
  </a-layout>
</template>
<script>
import Menu from "./menu/Menu";
import Cascader from '../utils/Cascader.js';
import { Avatar, Icon } from 'ant-design-vue'
import img from '#/assets/images/logo.jpeg';
import judgeDevice from './menu/judgeDevice';
import screenfull from "screenfull";
import store from "@/store";
import router from "@/router";

export default {
  meta: { title: '首页' },
  components: { Menu, Avatar, Icon, screenfull },
  data() {
    return {
      img: img,
      isFullscreenMark: true,
      spinning: false,
      collapsed: judgeDevice() === 'phone' ? true : false,
      menus: [],
      role: this.$store.state.role ? this.$store.state.role[0] : null,
      //当前路由的名字
      Breadcrumbs: [],
      isHidden: false,
      user: JSON.parse(localStorage.getItem('user')),
      theme: this.$store.state.theme,
      count: 0
    };
  },
  created() {
    this.menus = this.filterMenus(this.$store.state.roleMenus)
    // const addRoutes = generateAsyncRoutes(store.state.roleMenus)
    // addRoutes.forEach(item => {
    //   router.addRoute("admin", item)
    // })
  },
  watch: {
    $route: {
      handler(n) {
        this.generateBreadcrumb()
        if (n.path === '/admin/switch') {
          this.isHidden = false
        } else {
          this.isHidden = true
        }
      },
      immediate: true
    },
    '$store.state.theme'(n) {
      this.theme = n
    }
    //立即更新
  },
  methods: {
    //过滤去除后台路由中字段hidden=1的路由，
    filterMenus(menus) {
      const res = []
      menus.forEach(m => {
        const newItem = {};
        if (m.hidden != 1) {
          for (const key in m) {
            //这里有重复执行，但不影响最终效果
            newItem[key] = m[key]
          }
          if (newItem.children && newItem.children.length > 0) {
            newItem.children = this.filterMenus(m.children)
          }
          res.push(newItem)
        }
      })
      return res
    },
    isScreenFull() {
      // 需要注意的是 如果判断!screenfull.enabled 显示的是不支持全屏的话 是因为谷歌的版本问题  判断改为 !screenfull.isEnabled  就可以了

      if (!screenfull.isEnabled) {
        // 如果不支持进入全屏，发出不支持提示
        this.$message({
          message: "您的浏览器版本过低不支持全屏显示！",
          type: "warning"
        });
        return false;
      }
      screenfull.toggle();
      this.$nextTick(() => {
        this.isFullscreenMark = !this.isFullscreenMark
      })
    },
    async fresh() {
      if (store.state.roleMenus.length === 0) {
        this.spinning = true
        await this.$store.dispatch('getInfo').then(_ => {
          const addRoutes = generateAsyncRoutes(store.state.roleMenus)
          addRoutes.forEach(item => {
            router.addRoute("admin", item)
          })
          this.spinning = false
          this.$message.info('菜单生成成功,角色已更新，权限已更新')
        })
      } else {
        this.$message.info('刷新成功')
      }
    },
    generateBreadcrumb() {
      let name = this.$route.name
      let menus = this.menus
      //树形结构逆向查找name
      let cascaded = new Cascader(menus, name)
      cascaded.getNodeRoute(menus, name)
      this.Breadcrumbs = cascaded.outputArr
    },
    logout() {
      this.axios.post("admin/user/logout").then(res => {
        this.$message.success('退出成功')
        //清空所有localStorage
        localStorage.clear()
        location.href = '/auth/login'
      })
    },
    toHome() {
      //获取当前路由
      let route = this.$route
      if (route.path == '/admin/switch') return
      this.$router.push({ path: '/admin/switch' })
    }
  }
};
</script>

<style lang="scss">
#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.ant-pro-global-header {
  position: relative;
  height: 64px;
  padding: 0;
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
}

.icon_hold {
  text-align: center;
  cursor: pointer;

  &:hover {
    //    旋转180度
    transform: rotate(180deg);
    //延时1s
    transition: all .5s;
  }
}

.role_holder {
  margin-right: 10px;
  font-size: .8rem;

  @media (max-width: 768px) {
    display: none;
  }
}
</style>
